<template>
    <r-e-dialog :title="title" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel" width="600px">
        <el-form ref="formPublish" label-width="120px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="菜单/按钮名称" prop="menuName">
                    <el-input v-model="formPublish.menuName" style="width: 400px;" placeholder="请填写菜单名称"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="路由地址" prop="path">
                    <el-input v-model="formPublish.path" style="width: 400px;" placeholder="请填写路由地址"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="显示顺序" prop="orderNum">
                    <el-input type="number" v-model="formPublish.orderNum" style="width: 400px;" placeholder="请填写显示顺序"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="是否有效" prop="status">
                    <el-radio-group v-model="formPublish.status" size="small">
                        <el-radio-button :label="true">有效</el-radio-button>
                        <el-radio-button :label="false">无效</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="是否显示" prop="visible">
                    <el-radio-group v-model="formPublish.visible" size="small">
                        <el-radio-button :label="true">显示</el-radio-button>
                        <el-radio-button :label="false">隐藏</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {menuSave,menuUpdate} from "@/api/menu";
    import {MessageSuccess, MessageError} from "@custom/message";
    export default {
        name: "dialog-save-menu",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    uuid: null,
                    menuName: '',
                    orderNum: 0,
                    path: '',
                    menuType: '',
                    visible: true,
                    status: true,
                    parentUuid: null
                },
                title: "",
                type: null,
                rules: {
                    menuName:[{required: true, message: '请输入菜单名称', trigger: 'blur'}],
                    path:[{required: true, message: '请输入路由地址', trigger: 'blur'}],
                    orderNum:[{required: true, message: '请输入显示顺序', trigger: 'blur'}],
                    status:[{required: true, message: '请选择是否有效', trigger: 'change'}],
                    visible:[{required: true, message: '请输入是否显示', trigger: 'change'}],
                },
            }
        },
        methods: {
            openDialog(type, menuType, parentUuid, data) {
                this.type = type;
                this.title = type === 'edit' ? '修改权限' : '添加权限';
                if (data) this.formPublish = {...data};
                this.formPublish.menuType = menuType;
                if (parentUuid) this.formPublish.parentUuid = parentUuid;
                this.dialogVisible = true;
            },
            clickCancel() {
                this.$refs["formPublish"].resetFields();
                this.formPublish = {
                    uuid: null,
                    menuName: '',
                    orderNum: 0,
                    path: '',
                    menuType: '',
                    visible: true,
                    status: true,
                    parentUuid: null
                };
                this.$emit('handleSearch');
                this.dialogVisible = false;
            },
            clickSubmit() {
                let that = this;
                // 表单提交
                that.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        let {uuid, menuName, orderNum, path, menuType, visible, status, parentUuid} = that.formPublish;
                        if(this.type === 'add'){
                            that.menuSave({menuName, orderNum, path, menuType, visible, status, parentUuid});
                        }else {
                            that.menuUpdate({uuid,menuName, orderNum, path, menuType, visible, status});
                        }
                    }
                });
            },
            menuSave(data){
                let that = this;
                const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                menuSave(data).then(res=>{
                    loading.close();
                    MessageSuccess("添加成功");
                    that.clickCancel();
                }).catch(err=>loading.close());
            },
            menuUpdate(data){
                let that = this;
                const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                menuUpdate(data).then(res=>{
                    loading.close();
                    MessageSuccess("修改成功");
                    that.clickCancel();
                }).catch(err=>loading.close());
            }
        }
    }
</script>

<style scoped>

</style>